<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">

          <!-- Sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="1"
            label-for="sortBySelect"
            label-class="mt-50 mr-50 pl-0"
            class="mb-0 text-nowrap"
            style="min-width: 22rem"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <!-- ./Sorting  -->

          <!-- Search -->
          <b-form-group
            label="Search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            label-class="mt-50"
            class="mb-0"
            style="width: 30rem"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- ./Search -->
        </div>
      </b-card-body>

      <b-table
        responsive
        striped
        hover
        show-empty
        class="position-relative"
        :items="configurations"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        @row-dblclicked="item => openConfiguration(item.id)"
      >
        <template #cell(created)="{ item }">
          <span v-b-tooltip.hover.left.v-info="formatUpdatedTooltip(item)">
            {{ item.created | diffForHumansMaxTwoWeeks }}
          </span>
        </template>

        <template #cell(components)="{ item }">
          <b-badge pill :variant="item.components.length ? 'info' : 'muted'">
            {{ item.components.length ? item.components.length : '-' }}
          </b-badge>
        </template>
        <template #cell(instances)="{ item }">
          <b-badge pill :variant="item.instances.length ? 'info' : 'muted'">
            {{ item.instances.length ? item.instances.length : '-' }}
          </b-badge>
        </template>
        <template #cell(interfaces)="{ item }">
          <b-badge pill :variant="item.interfaces.length ? 'info' : 'muted'">
            {{ item.interfaces.length ? item.interfaces.length : '-' }}
          </b-badge>
        </template>
        <template #cell(tests)="{ item }">
          <b-badge pill :variant="item.tests.length ? 'info' : 'muted'">
            {{ item.tests.length ? item.tests.length : '-' }}
          </b-badge>
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-inline-flex">
            <b-button
              title="Open Configuration"
              variant="flat-primary"
              class="py-0 btn-icon"
              @click="openConfiguration(item.id)"
            >
              <font-awesome-icon :icon="['fas', 'folder']" />
            </b-button>
            <b-button
              title="Delete Configuration"
              variant="flat-danger"
              class="py-0 btn-icon"
              @click="openDeleteConfigurationModal(item)"
            >
              <feather-icon
                class="cursor-pointer text-danger"
                icon="TrashIcon"
                size="16"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- Page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-class="mt-50"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <!-- ./Page length -->

        <!-- Pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <!-- ./Pagination -->
      </b-card-body>
    </b-card>

    <DeleteConfigurationModal
      :config-obj="selectedConfig"
      @configuration-deleted="() => { $emit('configuration-deleted') }"
    />
  </div>
</template>

<script>
import CreateConfigurationModal from '@/views/Configurations/Modals/CreateConfigurationModal.vue'
import DeleteConfigurationModal from '@/views/Configurations/Modals/DeleteConfigurationModal.vue'
import {
  BPagination,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'ConfigurationsTableView',
  directives: { 'b-tooltip': VBTooltip },
  components: {
    DeleteConfigurationModal,
    BTable,
    BPagination,
  },
  props: {
    configurations: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedConfig: {
        name: '',
        description: '',
        effective_date: '',
        created: '',
        created_by: '',
        tests: [],
        instances: [],
        interfaces: [],
        components: [],
      },
      fields: [
        {
          key: 'name', label: 'Configuration', sortable: true, tdClass: 'text-nowrap',
        },
        {
          key: 'version',
          label: 'Version',
          sortable: true,
          tdClass: 'text-nowrap p-0',
          thClass: 'text-center',
        },
        {
          key: 'description', label: 'Description', sortable: true,
        },
        {
          key: 'effective_from', label: 'Effective From', sortable: true,
        },
        {
          key: 'created', label: 'Created', sortable: true,
        },
        {
          key: 'components',
          label: 'Components',
          sortable: false,
          thClass: 'text-center px-0',
          tdClass: 'text-center px-0',
          thStyle: 'max-width: 8rem;',
        },
        // {
        //   key: 'instances',
        //   label: 'Instances',
        //   sortable: false,
        //   thClass: 'text-center px-0',
        //   tdClass: 'text-center px-0',
        //   thStyle: 'max-width: 8rem',
        // },
        {
          key: 'interfaces',
          label: 'Interfaces',
          sortable: false,
          thClass: 'text-center px-0',
          tdClass: 'text-center px-0',
          thStyle: 'max-width: 8rem',
        },
        {
          key: 'tests',
          label: 'Tests',
          sortable: false,
          thClass: 'text-center px-0',
          tdClass: 'text-center px-0',
          thStyle: 'max-width: 4rem',
        },
        {
          key: 'actions', label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tempRowObject: {},
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    configurations(newVal) {
      this.totalRows = newVal.length
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatUpdatedTooltip(item) {
      return `Created by '${item.owner ? this.getUserUsername(item.owner) : '\'empty\''}' at ${this.$options.filters.formatDate(item.created)}`
    },
    openConfiguration(configId) {
      this.$store.dispatch('configurations/selectConfiguration', configId)
      this.$router.push(
        {
          name: 'configuration_details',
          params: { configurationId: configId },
        },
      )
    },
    openDeleteConfigurationModal(configObj) {
      this.selectedConfig = configObj
      this.$nextTick(() => {
        this.$bvModal.show('delete-configuration-modal')
      })
    },
  },
}
</script>
