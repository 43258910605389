<template>
  <b-modal
    id="create-configuration-modal"
    title="Create new Configuration"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Create Configuration'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    no-close-on-backdrop
    @show="onShow"
    @hidden="onClose"
    @ok.prevent="onSubmit"
  >
    <!-- Name -->
    <b-form-group label="Name" label-for="config-name">
      <b-form-input
        id="config-name"
        v-model="configObj.name"
        placeholder="Configuration name"
        autocomplete="off"
        autocapitalize="characters"
        required
      />
    </b-form-group>

    <!-- Version -->
    <b-form-group label="Version" label-for="config-version">
      <b-form-input
        id="config-version"
        v-model="configObj.version"
        type="text"
        placeholder="Configuration version"
        autocomplete="off"
        autocapitalize="off"
      />
    </b-form-group>

    <!-- Effective Date -->
    <b-form-group label="Date effective from" label-for="config-date">
      <b-input-group>
        <b-form-input
          id="config-date"
          v-model="configObj.effective_from"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="configObj.effective_from"
            button-only
            right
            size="sm"
            button-variant="info"
            locale="en-AU"
            aria-controls="config-date"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <!-- Description -->
    <b-form-group label="Description" label-for="config-desc">
      <b-form-textarea
        id="config-desc"
        v-model="configObj.description"
        placeholder="Give a brief description of the Configuration..."
        rows="4"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import router from '@/router'
import axiosIns from '@/libs/axios'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CreateConfigurationModal',
  directives: { Ripple },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const loading = ref(false)
    const configObj = ref({
      name: '',
      version: '',
      description: '',
      created: '',
    })

    const onShow = () => {
      loading.value = false
      configObj.value = {
        model: modelId,
        name: '',
        version: '',
        effective_from: '',
        description: '',
      }
    }
    const onSubmit = () => {
      loading.value = true
      const params = { model: modelId }
      axiosIns
        .post('/api/v2/domain_model/build_configurations', configObj.value)
        .then(response => {
          context.emit('configuration-created')
          context.root.$bvModal.hide('create-configuration-modal')
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create configuration',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }
    const onClose = () => {}

    return {
      loading,
      configObj,

      onShow,
      onSubmit,
      onClose,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
