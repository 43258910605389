<template>
  <div id="ConfigurationListView">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Configurations</h2>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          @click="() => $bvModal.show('create-configuration-modal')"
        >
          <feather-icon icon="PlusIcon" />
          Create Configuration
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Refresh"
          variant="outline-info"
          size="sm"
          @click="fetchConfigurations"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Configurations...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <configurations-table-view
        class="w-100"
        :configurations="allConfigurations"
        @configuration-deleted="fetchConfigurations"
      />
    </div>

    <CreateConfigurationModal @configuration-created="fetchConfigurations" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateConfigurationModal from '@/views/Configurations/Modals/CreateConfigurationModal.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import Ripple from 'vue-ripple-directive'
import ConfigurationsTableView from './ConfigurationsTableView.vue'

export default {
  name: 'Configurations',
  components: {
    AtomSpinner,
    ConfigurationsTableView,
    CreateConfigurationModal,
  },
  directives: { Ripple },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    onMounted(() => { fetchConfigurations() })

    const allConfigurations = ref([])
    const loading = ref(false)

    const fetchConfigurations = () => {
      loading.value = true
      const params = { model: modelId, sorting: 'end' }
      axiosIns
        .get('/api/v2/domain_model/build_configurations', { params })
        .then(response => { allConfigurations.value = response.data })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch configurations',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    const openConfiguration = configurationId => {
      router.push({ name: 'configuration_details', params: { configurationId } })
    }

    return {
      loading,
      allConfigurations,
      fetchConfigurations,
      openConfiguration,
    }
  },
}
</script>
